<template>
  <div class="customerMessage page-container-table">
    <div class="tableBox">
      <Table :operation-button="operationButton" :item-data="itemData" :list-data="listData" :loading="loading">
        <template #typeMessage="{ row }">
          <span v-if="row.status===1" class="unread" />
          {{ row.typeMessage }}
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import { announceResourceByPage, announceRead } from '@/http/custApi/login'
export default {
  components: { Table, Pagination },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0,
      itemData: [
        { label: '类型', prop: 'typeMessage', width: '150px' },
        { label: '内容', prop: 'content', html: true, width: '350px' },
        { label: '时间', prop: 'createTime', width: '150px' }
      ],
      listData: [],
      loading: false,
      operationButton: [{ bType: 'primary', label: '前往查看', handleEvent: this.viewInfoTable }]
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    viewInfoTable(row) {
      // 1是授信额度  2是采购合同  3是保证金   4是结算单  5是提单
      switch (row.typeCode) {
        case 1:
          this.$router.push({ path: '/quotaManage/quotaInfo', query: { id: row.businessId }})
          break
        case 2:
          this.$router.push({ path: '/purchaseContractManage/contractInfo', query: { id: row.businessId }})
          break
        case 3:
          this.$router.push({ path: '/purchaseContractManage/contractInfo', query: { id: row.businessId }})
          break
        case 4:
          this.$router.push({ path: '/financialManage/custBillingDetails', query: { id: row.businessId }})
          break
        case 5:
          this.$router.push({ path: '/takeDeliveryManage/takeDeliveryInfo', query: { id: row.businessId }})
          break
      }
      announceRead(row.id)
      this.$set(row, 'status', 2)
    },
    getdata() {
      announceResourceByPage(this.formInline, res => {
        if (!res.data) return
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss">
.customerMessage{
  background-color: #fff;
  .tableBox{
    margin-top: 24px;
  }
  .unread{
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: $pointClearColor;
    display: inline-block;
    transform: translateY(-4px);
  }
}
</style>
